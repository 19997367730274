import { ReadonlyURLSearchParams, useSearchParams } from "next/navigation";

// Erstatter den gamle useRouter().query. Henter alle search queries og legger dem inn i et object.
export const useQueryObject = () => {
  const searchParams = useSearchParams();
  return Object.fromEntries(searchParams.entries());
};

export const getPathWithParams = (
  pathname: string,
  params: string | Record<string, string> | ReadonlyURLSearchParams
): string => {
  return `${pathname}?${new URLSearchParams(params).toString()}`;
};
