
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable i18next/no-literal-string */
import { BiblioteksentralenLogoWithName } from "@biblioteksentralen/icons";
import { Box, Container, Flex, Heading, Link, Stack, Text, VStack } from "@biblioteksentralen/react";
import { filters, imageUrlBuilder } from "@libry-content/common";
import { Site, SplashPage as SplashPageType } from "@libry-content/types";
import { GetStaticProps } from "next";
import { groq } from "next-sanity";
import { LinksToAllSites } from "../components/LinksToAllSites";
import SEO from "../components/SEO";
import { usePreviewContext } from "../components/preview/previewContext";
import SplashPageFooter from "../components/splashPage/SplashPageFooter";
import { sanityClient, usePreviewSubscription } from "../utils/sanity/client";
const query = groq `{
  "splashPage": *[_type == "splashPage"][0],
  "sites": *[_type == "site" && ${filters.isNotDraft} && isLive] {
    municipality,
    customDomain,
    subdomain,
    defaultLanguage,
    _id,
    name
  }
}`;
type Props = {
    splashPage?: SplashPageType;
    sites?: Pick<Site, "municipality" | "customDomain" | "subdomain" | "_id" | "name" | "defaultLanguage">[];
};
const getStaticProps: GetStaticProps = async () => {
    const splashPage = await sanityClient.fetch<Props>(query);
    return {
        props: splashPage,
        revalidate: 60
    };
};
function SplashPage(props: Props) {
    const context = usePreviewContext();
    const { data } = usePreviewSubscription(query, {
        initialData: props,
        enabled: context.isOn
    });
    return (<>
      <SEO title="Libry Content" description={data.splashPage?.someDescription ?? "Nettsidebygger for bibliotekene i Norge"} sanityImage={data.splashPage?.someBilde} canonicalPath="/" icon="/favicon.png"/>
      <Header {...props}/>
      <Sites {...props}/>
      <SplashPageFooter background="gray.100"/>
    </>);
}
const Header = (props: Props) => (<Box as="section" minH={"110vh"} backgroundColor="blackAlpha.700" overflow="hidden" position="relative" isolation="isolate" color={"white"} background={`linear-gradient(rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.65)), url("${imageUrlBuilder(props.splashPage?.image)
        ?.width(1200)
        .format("webp")
        .quality(80)
        .url()}")`} backgroundSize="cover" backgroundPosition="center" // FIXME: Background image that respects hotspot
>
    <Flex flexDirection="column" justifyContent="center" position="relative" paddingY="max(10vmin, 3rem)" minH={"100vh"}>
      <Container maxW={`container.lg`} paddingX={{ base: "1rem", md: "2rem" }}>
        <VStack height="100%" paddingTop="5vh" textAlign="center">
          <Link href="https://www.bibsent.no/">
            <Box position="absolute" top="1rem" left="1rem" color="white" _hover={{ color: "#ddd" }}>
              <BiblioteksentralenLogoWithName aria-label="Biblioteksentralen" height="2.5em"/>
            </Box>
          </Link>
          <Heading as="h1" fontSize={{ base: "4xl", md: "6xl" }} fontFamily="serif" lineHeight={1}>
            Libry Content
          </Heading>
          <Text fontSize={{ md: "lg" }}>Nettsider og formidlingsplattform skreddersydd for bibliotek</Text>
          <Link href="https://librycontent.no/" marginTop="1.5rem !important" variant="plain" backgroundColor="hsl(37deg 100% 72%)" _hover={{ backgroundColor: "hsl(37deg 100% 78%);" }} color="black" fontSize="xs" borderRadius="sm" padding=".75em 2em">
            Les mer om Libry Content her
          </Link>
        </VStack>
      </Container>
    </Flex>
  </Box>);
const Sites = (props: Props) => (<Box paddingY="6rem">
    <Container maxW="container.md">
      <Stack spacing="1rem" alignItems="flex-start">
        <Heading as="h2" size="md">
          Våre kunder
        </Heading>
        <Text>Besøk et av bibliotekene som bruker Libry Content i dag:</Text>
        <LinksToAllSites sites={props.sites} paddingTop="1rem"/>
      </Stack>
    </Container>
  </Box>);
export default SplashPage;

    async function __Next_Translate__getStaticProps__18d35b2a4e1__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__18d35b2a4e1__ as getStaticProps }
  